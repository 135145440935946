import React from "react"
import styled from "styled-components"
import { FAQText } from "../../components"
import CollapsibleList from "../../components/CollapsibleList"
import theme from "../../theme"

const DisclamierPage: React.FC = () => {
  return (
    <Container>
      <h1>Privacy Notice & Policy</h1>
      {data.content.map(item => (
        <CollapsibleList key={item.id} title={item.title}>
          {item.text.map((textItem, index) => (
            <FAQText
              key={`textItem-${item.id}.${index}`}
              text={{
                id: textItem.id,
                numbered: false,
                description: textItem.description,
                points: textItem.points || [],
              }}
            />
          ))}
        </CollapsibleList>
      ))}
    </Container>
  )
}

export default DisclamierPage

const Container = styled.div`
  padding: 0 96px 99px 58px;
  h1 {
    font-family: "PlayfairDisplay";
    font-style: normal;
    font-weight: 800;
    font-size: 86px;
    line-height: 115px;
    text-align: center;
    padding: 0rem 10rem 1rem 10rem;

    @media screen and (max-width: ${theme.maxBreakpoints.xl}) {
      padding: 0rem 6rem 1rem 6rem;
    }
    @media screen and (max-width: ${theme.maxBreakpoints.lg}) {
      padding: 0rem 3rem 1rem 3rem;
    }
    @media screen and (max-width: ${theme.maxBreakpoints.md}) {
      padding: 0rem 0rem 1rem 0rem;
      font-size: 76px;
    }
    @media screen and (max-width: ${theme.maxBreakpoints.sm}) {
      line-height: 95px;
      font-size: 56px;
    }
  }
  @media screen and (max-width: ${theme.maxBreakpoints.md}) {
    padding: 0rem 1.5rem 1rem 1.5rem;
  }
  @media screen and (max-width: ${theme.maxBreakpoints.sm}) {
    padding-right: 2rem;
  }
`

const data = {
  content: [
    {
      id: 1,
      title: "Introduction",
      text: [
        {
          id: 1,
          description:
            "Workerslife (‘We’, ‘us’ and ‘our’) cares about the privacy, security and online safety of our customers’ (‘you’ and ‘your’) personal information. We take our responsibility to protect your personal information very seriously. This notice explains how we collect, use, share and protect your personal information, as required by the Protection of Personal Information Act (POPIA). Using our services and any disagreements about your privacy are subject to this notice.",
        },
        {
          id: 2,
          description:
            "As our business grows and changes, we may need to update this notice to reflect those changes. If changes affect you personally, we will try and contact you directly because it is important to us that you understand at all times how your personal information is used. Please make sure that we always have your latest contact details.",
        },
      ],
    },
    {
      id: 2,
      title: "The meaning of processing your personal information",
      text: [
        {
          id: 1,
          description:
            "To help you understand this notice, we include the explanations of the terms ‘personal information’ and ‘process’ as used in POPIA:",
        },
        {
          id: 2,
          description:
            "Personal information and special personal information are any information relating to an identifiable living or juristic person. Some examples are: race, gender, marital status, nationality, age, physical or mental health, disability, language, education, identity number, telephone number, email, postal or street address, biometric information and financial, criminal or employment history.",
        },
        {
          id: 3,
          description:
            "Process means any operation or activity, whether automated or not, concerning personal information, including: collection, receipt, recording, organisation, collation, storage, updating or modification, retrieval, alteration, consultation, use, dissemination by means of transmission, distribution or making available in any other form, merging, linking, as well as blocking, degradation, erasure or destruction of information. “Processing” will have a similar meaning.",
        },
      ],
    },
    {
      id: 3,
      title: "Why we process your personal information",
      text: [
        {
          id: 1,
          description:
            "We have to process some of your personal information in order to provide you with our products and services, and also as required by insurance, tax and other legislation. For example: When you buy one of our services or products, we collect your name, contact information and payment information to complete the transaction.",
        },
        {
          id: 2,
          description:
            "Your personal information is also gathered through our website and other channels we use to distribute, communicate and deliver our services. We use your personal information in order to do the following:",
          points: [
            "identify you",
            "process your requests or instructions",
            "manage your policy",
            "follow the laws of South Africa",
            "detect and prevent fraud",
            "offer other products and services to you",
          ],
        },
        {
          id: 3,
          description:
            "Because of the nature of our business, we sometimes use other parties like our service providers and administrators who in turn process your personal information.",
        },
      ],
    },
    {
      id: 4,
      title: "When we share your personal information",
      text: [
        {
          id: 1,
          description:
            "In order to provide our products and services to you, we may share your personal information with other insurers, industry bodies, credit agencies and our service providers. These are some examples of when we will share your personal information:",
          points: [
            "When required by any regulatory authority, such as The Financial Sector Conduct Authority (FSCA) or Prudential Authority.",
            "When required by legislation or any legal process.",
            "To protect and defend our rights and property, including our intellectual property.",
            "When you have given us direct permission to do so. We will never sell your personal Information or share it with other parties for their own marketing use, unless you have given us direct permission.",
          ],
        },
      ],
    },
    {
      id: 5,
      title: "Some information is automatically collected",
      text: [
        {
          id: 1,
          description:
            "When you use any of our digital channels like websites and apps, we receive and store information generated by your activities (usage data gathered by cookies) and other information that are automatically collected from your browser or mobile device. Most of this data is generally not personally identifiable. However, some of this data, either alone or when linked with other information, may allow your identity to be discovered. We treat this combined data as personal information and we protect it accordingly.",
          points: [
            "Cookies are small text files that are created when you view a website. They gather usage data which includes information about the sites you visited, the number of times you visit, the date, time and length of your visit, which products or services you viewed and which areas of the site you visited. We may assign you one or more unique identifiers to help keep track of your future visits.",
            "Other information automatically collected may include your IP address, browser type and version, preferred language, geographic location, wireless or Bluetooth technology on your device, operating system and computer platform.",
          ],
        },
      ],
    },
    {
      id: 6,
      title: "Information collected through online advertising",
      text: [
        {
          id: 1,
          description:
            "We use service providers (such as Google, DoubleClick and Programmatic Buying) to help us deliver our banner advertisements and other online communications. To understand which types of offers, promotions and advertising are most appealing to our customers, these service providers may collect and use some of your personal information. This information is aggregated and cannot be linked to you.",
          points: [
            "Data aggregation is any process in which information is gathered and expressed in a summary form using specific variables such as age, profession, income and interests.",
          ],
        },
        {
          id: 2,
          description:
            "These service providers show Workerslife’s ads on sites on the internet, and they use the information stored in cookies based on your prior visits to Workerslife’s website. If you don’t want your personal information to be used in this way, you can opt out of the use of cookies, by visiting any of the following sites:",
          points: [
            "Google at http://www.google.com/policies/privacy/ads/",
            "Network Advertising Initiative at http://www.networkadvertising.org/managing/opt_out.asp",
          ],
        },
      ],
    },
    {
      id: 7,
      title: "How we protect your data",
      text: [
        {
          id: 1,
          description:
            "We take every reasonable precaution to protect your personal information (including information about your activities) from theft, unauthorised access and disruption of services. Our security controls are designed to maintain an appropriate level of data confidentiality, integrity, and availability. We regularly test our website, data centres, systems, and other assets for security vulnerabilities.",
        },
        {
          id: 2,
          description:
            "However, we cannot guarantee the security of any personal information that you willingly disclose online. Please note that we process and collect your personal information when you use our website, contact us electronically or complete a quotation or application form online. When you use any of our online services, you will be given a username and password. Always keep this information safe and never disclose it to anyone.",
        },
      ],
    },
    {
      id: 8,
      title: "Receiving marketing from us",
      text: [
        {
          id: 1,
          description:
            "We like to keep our customers informed of the latest products and services offered by us and our service providers. When you buy a new product or service from us, we will ask you what your marketing preferences are. If you do not want to receive marketing from us, you can change your preference by contacting us on the details on your policy document or on the details below.",
        },
        {
          id: 2,
          description:
            "Note that even if you choose not to receive marketing from us, we may still communicate with you regarding things like security updates, product functionality, responses to service requests and for other transactional or administrative purposes.",
        },
      ],
    },
    {
      id: 9,
      title: "Accessing, changing or removing your personal information",
      text: [
        {
          id: 1,
          description:
            "You may ask us to access, change or remove your personal information from our records. If legislation allows, we may charge an administrative fee, but we will always inform you of any cost before performing your request.",
        },
      ],
    },
    {
      id: 10,
      title: "Contact us ",
      text: [
        {
          id: 1,
          description:
            "If you have any questions, or you want to update your personal information or preferences, please contact our Privacy Office on the details below:",
          points: [
            "Postal address: PO Box 11497, Hatfield, 0028",
            "Physical address: 1282 Heuwel Road, Centurion, 0046",
            "Telephone No: +27 12 305 7400",
            "Facsimile No: +27 861 530 530",
          ],
        },
      ],
    },
  ],
}
